<template>
    <CompForm ref="comp_form" :value="formdata" :forms="forms" :data-watch="watch_form" :param="param" :config="formConfig" @on-submit="onSubmit"></CompForm>
</template>

<script>
import CompForm from "../../customform/components/CompForm.vue"

export default {
    components: {
        CompForm,
    },

    props: {
        code: String,
    },

    watch: {
        code: {
            handler(v) {
                this.param = { code: v }
            },
            immediate: true,
        },
    },

    data() {
        return {
            forms: [
                {
                    type: "input",
                    name: "userName",
                    title: "姓名",
                    max: 20,
                    required: true,
                },
                {
                    type: "input",
                    name: "formerName",
                    title: "曾用名",
                    max: 20,
                },
                {
                    type: "addr",
                    name: "addr",
                    title: "现住址",
                    required: true,
                },
                {
                    type: "select",
                    name: "idNumType",
                    title: "证件类型",
                    options: [
                        {
                            label: "国内身份证",
                            value: "1",
                        },
                        {
                            label: "港澳台证件",
                            value: "2",
                        },
                        {
                            label: "护照",
                            value: "3",
                        },
                        {
                            label: "其他",
                            value: "4",
                        },
                    ],
                },
                {
                    type: "input",
                    name: "idNum",
                    title: "证件号码",
                    verify: "idNum",
                    max: 18,
                },
                {
                    type: "input",
                    name: "mobile",
                    title: "联系电话",
                    max: 20,
                },
            ],

            // 监听表单值
            watch_form: {
                idNumType: value => {
                    for (let i = 0, fs = this.forms; i < fs.length; i++) {
                        let f = fs[i]
                        if (f.name === "idNum") {
                            f.verify = value == 1 ? "idNum" : null
                            break
                        }
                    }
                },
            },

            param: {},

            formConfig: {
                switch: {
                    open: "1",
                    close: "2",
                },
            },

            formdata: {},
        }
    },
    methods: {
        setFormData(data) {
            this.formdata = data
        },

        onSubmit(evt) {
            this.$emit("on-submit", evt)
        },

        reset() {
            this.$refs.comp_form.setEmpty()
        },
    },
}
</script>

<style scoped lang="less"></style>
